
import { defineComponent, ref } from 'vue';
import OverlayModal from '@/components/Calendar/OverlayModal.vue';
import Multiselect from '@vueform/multiselect';
import swal from 'sweetalert2';
import { useI18n } from 'vue-i18n';
import { BussinesClientByNewOrderObject, SaveCrmCustomerRequest } from '@/models/Interfaces';
import { generalStore } from '@/store';
import { CrmCustomerEditorModel } from '@/models/CrmCustomers';
import router from '@/router';
import { api } from '@/services/Api';
import Calendar from 'primevue/calendar';
import { DateConstants } from '@/models/Constants';
import moment from 'moment';
import q from 'q';
import { CrmCustomerSaveChanges } from '@/models/Enums';
import _ from 'lodash';
import { getFlagChanges } from '@/helper';

export default defineComponent({
    components: {
        Multiselect,
        OverlayModal,
        Calendar
    },
    name: 'CreateCustomerModal',
    async setup(props: any, { emit }: any) {
        const { t } = useI18n();
        const isOpen = ref(false);
        const bussinesClients = ref<BussinesClientByNewOrderObject[]>([]);
        const originCrmCustomer = ref(new CrmCustomerEditorModel());
        const crmCustomer = ref(new CrmCustomerEditorModel());
        const calendarBirthdate = ref<Date | null>(null);

        // validation refs
        const firstNameField = ref<HTMLInputElement>();
        const firstNameFieldError = ref<HTMLInputElement>();
        const lastNameField = ref<HTMLInputElement>();
        const lastNameFieldError = ref<HTMLInputElement>();
        const emailField = ref<HTMLInputElement>();
        const emailFieldError = ref<HTMLInputElement>();
        const phoneField = ref<HTMLInputElement>();
        const phoneFieldError = ref<HTMLInputElement>();

        async function open(crmCustomerToEdit: CrmCustomerEditorModel | null = null) {
            swal.showLoading();
            await q.delay(400);
            if (crmCustomerToEdit) {
                originCrmCustomer.value = _.clone(crmCustomerToEdit);
                crmCustomer.value = _.clone(crmCustomerToEdit);
            }
            bussinesClients.value = await generalStore.dispatch('loadBusinessClients');
            calendarBirthdate.value = crmCustomerToEdit?.birthdate
                ? moment(crmCustomerToEdit.birthdate, DateConstants.DATE_FORMAT_SHOW).toDate()
                : null;
            isOpen.value = true;
            swal.close();
        }
        function close() {
            isOpen.value = false;
        }
        function birthDateChanged(event: Date) {
            crmCustomer.value.birthdate = moment(event).format(DateConstants.DATE_FORMAT_SHOW);
        }
        function setValidator(codes: { key: string; msg: string }[]) {
            const firstNameError = codes.find(x => x.key == 'FirstName');
            if (firstNameError) {
                firstNameField.value?.setCustomValidity(firstNameError.msg);
                if (firstNameFieldError.value) {
                    firstNameFieldError.value.textContent = firstNameError.msg;
                }
            }
            const lastNameError = codes.find(x => x.key == 'LastName');
            if (lastNameError) {
                lastNameField.value?.setCustomValidity(lastNameError.msg);
                if (lastNameFieldError.value) {
                    lastNameFieldError.value.textContent = lastNameError.msg;
                }
            }
            const emailError = codes.find(x => x.key == 'Email');
            if (emailError) {
                emailField.value?.setCustomValidity(emailError.msg);
                if (emailFieldError.value) {
                    emailFieldError.value.textContent = emailError.msg;
                }
            }
            const phoneError = codes.find(x => x.key == 'Mobile');
            if (phoneError) {
                phoneField.value?.setCustomValidity(phoneError.msg);
                if (phoneFieldError.value) {
                    phoneFieldError.value.textContent = phoneError.msg;
                }
            }
            // birthdate validation
            const birthdateError = codes.find(x => x.key == 'Birthdate');
            if (birthdateError) {
                const birthdateInput = document.getElementById('birthdateField') as HTMLInputElement;
                const birthdateFieldError = document.getElementById('birthdateFieldError') as HTMLInputElement;
                const firstSpan = birthdateFieldError.querySelector('span') as HTMLInputElement;
                birthdateInput.style.setProperty('border', '1px solid red', 'important');
                birthdateInput.setCustomValidity(birthdateError.msg);
                birthdateFieldError.style.setProperty('display', 'block', 'important');
                firstSpan.textContent = birthdateError.msg;
            }
        }
        function clearValidator() {
            // required
            // firstName validation
            firstNameField.value?.setCustomValidity('');
            if (firstNameFieldError.value) {
                firstNameFieldError.value.textContent = t('valid.isRequired');
            }

            // required
            // lastName validation
            lastNameField.value?.setCustomValidity('');
            if (lastNameFieldError.value) {
                lastNameFieldError.value.textContent = t('valid.isRequired');
            }

            // email validation
            emailField.value?.setCustomValidity('');
            if (emailFieldError.value) {
                emailFieldError.value.textContent = t('valid.isRequired');
            }

            // required
            // phone validation
            phoneField.value?.setCustomValidity('');
            if (phoneFieldError.value) {
                phoneFieldError.value.textContent = t('valid.isRequired');
            }

            // birthdate validation
            const birthdateInput = document.getElementById('birthdateField') as HTMLInputElement;
            if (birthdateInput) {
                birthdateInput.setCustomValidity('');
                birthdateInput.style.setProperty('border', '1px solid #a8e12a', 'important');
            }
            const birthdateInputError = document.getElementById('birthdateFieldError') as HTMLInputElement;
            if (birthdateInputError) {
                birthdateInputError.style.setProperty('display', 'none', 'important');
            }
        }
        function getBirthdateString(date: Date | null | string) {
            if ((date instanceof Date && !isNaN(date.getTime())) == false) {
                return null;
            }
            return moment(calendarBirthdate.value).format(DateConstants.SERVER_NODATIME_LOCAL_DATE_FORMAT);
        }
        async function onSave() {
            swal.showLoading();
            let changes;
            if (crmCustomer.value.id) {
                changes = getFlagChanges(originCrmCustomer.value, crmCustomer.value, CrmCustomerSaveChanges, key => {
                    if (key === 'businessClientId') return 'BusinessClient';
                    return null;
                });
            } else {
                changes = CrmCustomerSaveChanges.AllExcludeRemarks;
            }
            const req: SaveCrmCustomerRequest = {
                id: crmCustomer.value.id,
                firstName: crmCustomer.value.firstName,
                lastName: crmCustomer.value.lastName,
                email: crmCustomer.value.email,
                mobile: crmCustomer.value.mobile,
                birthdate: getBirthdateString(calendarBirthdate.value),
                businessClientId: crmCustomer.value.businessClientId,
                remarks: '',
                changes: changes
            };

            const apiResponse = await api.saveCrmCustomer(req);
            if (apiResponse.validationErrors?.length) {
                setValidator(apiResponse.validationErrors);
                swal.close();
                return null;
            }
            if (apiResponse.errorMessage || !apiResponse.data) {
                swal.fire({
                    icon: 'error',
                    text: apiResponse.errorMessage || 'No data'
                });
                return null;
            }

            swal.close();
            const customer = apiResponse.data;
            return {
                birthdate: customer.birthdate,
                businessClientId: customer.businessClientId,
                email: customer.email,
                firstName: customer.firstName,
                lastName: customer.lastName,
                mobile: customer.mobile,
                id: customer.id,
                businessClientName:
                    bussinesClients.value.find((x: BussinesClientByNewOrderObject) => x.id === customer.businessClientId)?.name ?? ''
            } as CrmCustomerEditorModel;
        }
        const submitForm = async (event: any) => {
            clearValidator();
            event.target.classList.add('was-validated');
            if (event.target.checkValidity() === false) {
                event.preventDefault();
                event.stopPropagation();
                return;
            }
            const crmCustomerToUpdate = await onSave();

            if (crmCustomerToUpdate == null) {
                return;
            }
            close();
            if (crmCustomer.value.id) {
                crmCustomerToUpdate.businessClientName =
                    bussinesClients.value.find((x: BussinesClientByNewOrderObject) => x.id == crmCustomer.value.businessClientId)?.name ??
                    '';
                emit('updatecrmCustomerDetails', crmCustomerToUpdate);
            } else {
                router.push({ name: 'crm-customer-details', params: { id: String(crmCustomerToUpdate.id) } });
            }
        };

        return {
            isOpen,
            open,
            close,
            crmCustomer,
            bussinesClients,
            submitForm,
            birthDateChanged,
            phoneField,
            phoneFieldError,
            emailField,
            emailFieldError,
            lastNameField,
            lastNameFieldError,
            firstNameField,
            firstNameFieldError,
            calendarBirthdate,
            originCrmCustomer
        };
    }
});
