
import { defineComponent } from 'vue';
import router from '@/router';

export default defineComponent({
    name: 'CrmCustomerPageHeader',
    props: {
        title: {
            type: String,
            required: true
        },
        bntName: {
            type: String
        }
    },
    async setup() {
        function back() {
            router.go(-1);
        }
        return {
            back
        };
    }
});
