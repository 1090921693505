
import { defineComponent, ref } from 'vue';
import OverlayModal from '@/components/Calendar/OverlayModal.vue';
import { api } from '@/services/Api';
import swal from 'sweetalert2';

export default defineComponent({
    components: {
        OverlayModal
    },
    name: 'CrmCustomerChatMessagePopup',
    async setup(props, { emit }) {
        const crmCustomerId = ref('');
        const crmCustomerFullName = ref('');
        const msgSent = ref(false);
        const isOpen = ref(false);
        const message = ref('');
        async function open(crmCustomerIdParam: string, crmCustomerFullNameParam: string) {
            crmCustomerId.value = crmCustomerIdParam;
            crmCustomerFullName.value = crmCustomerFullNameParam;
            message.value = '';
            isOpen.value = true;
        }
        function close() {
            isOpen.value = false;
        }
        async function sendMsg() {
            msgSent.value = true;
            if (!message.value.trim()) {
                msgSent.value = false;
                return;
            }
            if (msgSent.value) {
                swal.showLoading();
                const apiResult = await api.SendCrmCustomerMessage(crmCustomerId.value, message.value);
                if (apiResult.validationErrors?.length) {
                    const html = apiResult.validationErrors
                        .map(x => {
                            return `<div>${x.key}: ${x.msg}</div>`;
                        })
                        .join(',');
                    swal.fire({
                        icon: 'error',
                        html: html
                    });
                    return;
                }
                if (apiResult.errorMessage || !apiResult.data) {
                    swal.fire({
                        icon: 'error',
                        text: apiResult.errorMessage || 'No data'
                    });
                    return;
                }
                message.value = '';
                emit('messageSend');
                close();
                swal.close();
            }
        }

        return {
            isOpen,
            open,
            close,
            message,
            sendMsg,
            crmCustomerFullName
        };
    }
});
