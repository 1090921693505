import _ from 'lodash';
export function createDebounce() {
    let timeout = 0;
    return function(fnc: any, delayMs?: number) {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
            fnc();
        }, delayMs || 400);
    };
}
function getChangedKeys(o1: any, o2: any) {
    const keys = _.union(_.keys(o1), _.keys(o2));
    return _.filter(keys, function(key) {
        return o1[key] !== o2[key];
    });
}
export function getFlagChanges<T extends object, E extends Record<keyof E, number>>(
    origin: T,
    updated: T,
    enumType: E,
    specialKeyMappingFn?: (key: string) => keyof E | null
): number {
    const keysChanged = getChangedKeys(origin, updated);
    const changeFlag = keysChanged.reduce((acc, key) => {
        let enumKey: keyof E | null = null;
        if (specialKeyMappingFn) {
            enumKey = specialKeyMappingFn(key);
        }
        
        enumKey = enumKey || ((key.charAt(0).toUpperCase() + key.slice(1)) as keyof E);
        const enumValue = enumType[enumKey];

        if (enumValue !== undefined && (acc & enumValue) === 0) {
            return acc | enumValue;
        }
        return acc;
    }, 0);

    return changeFlag;
}
