import { ComparisonOperator, ReminderSaveChanges, CrmCustomerMessageType } from '@/models/Enums';
export class CrmCustomerEditorModel {
    id: string | null = null;
    firstName = '';
    lastName = '';
    birthdate: string | null = null;
    businessClientId: number | null = null;
    email = '';
    mobile = '';
    businessClientName = '';
}

export class CrmCustomerDetailsModel {
    id = '';
    firstName = '';
    lastName = '';
    fullName = '';
    birthdate: string | null = null;
    businessClientId: number | null = null;
    businessClientName = '';
    email = '';
    mobile = '';
    remarks = '';
    createdAt = '';
    lastOrderCreateDate = '';
    orderTotalQuantity = 0;
    ordersTotalAmount = 0;
    leadTotalQuantity = 0;
    reminders: CrmCustomerReminderDetailsModel[] = [];
    messages: CrmCustomerMessage[] = [];
    timingChatMessage: CrmCustomerTimingChatMessage[] = [];
}
export class CrmCustomerReminderDetailsModel {
    id = '';
    name = '';
    alertDateTime = '';
    active = true;
    onlyForMe = true;
    relativeInterval = '';
}

export class UserReminder {
    id = '';
    name = '';
    alertDateTime = '';
    active = true;
    crmCustomerFullName = true;
    crmCustomerId = '';
    relativeInterval = '';
    more = false;
}

export class CrmCustomerListModel {
    id = '';
    firstName = '';
    lastName = '';
    fullName = '';
    email = '';
    businessClientName = '';
    businessClientId: number | null = null;
    phone = '';
    orderTotalAmount = 0;
    lastChatDate = '';
    lastOrderCreateDate = '';
    orderTotalQuantity = 0;
    leadTotalQuantity = 0;
    createdAt = '';
    remarks = '';
    unreadMessageCount = 0;
}

export class CrmCustomerListFilter {
    pageIndex = 0;
    keywords = '';
    orderBy = 'Id';
    asc = false;
    businessClientId: number | null = null;
    createDateFrom: string | null = null;
    createDateTo: string | null = null;
    lastOrderDateFrom: string | null = null;
    lastOrderDateTo: string | null = null;
    leadTotalQuantity: number | null = null;
    leadTotalQuantityComparison = ComparisonOperator.LessThan;
    orderTotalAmount: number | null = null;
    orderTotalAmountComparison = ComparisonOperator.LessThan;
    orderTotalQuantity: number | null = null;
    orderTotalQuantityComparison = ComparisonOperator.LessThan;
}

export class CrmCustomerReminderEditorModel {
    id: string | null = null;
    name = '';
    active = true;
    onlyForMe = false;
    alertDate: string | null = null;
    alertTime: string | null = null;
    relativeInterval = '';
}

export class CrmCustomerReminderSaveRequest {
    id: string | null = null;
    name = '';
    active = true;
    onlyForMe = false;
    alertDateTime: string | null = null;
    crmCustomerId = '';
    changes: ReminderSaveChanges | null = null;
}
export class CrmCustomerMessage {
    id: string;
    createdAt: string;
    from: string;
    isMe: boolean;
    message: string;
    attachments: CrmCustomerMessageAttachment[];
    type: CrmCustomerMessageType | null;
    messageValues: Record<string, unknown> | null;
    constructor() {
        this.id = '';
        this.createdAt = '';
        this.from = '';
        this.isMe = false;
        this.message = '';
        this.type = null;
        this.messageValues = null;
        this.attachments = [];
    }
}
export class CrmCustomerTimingChatMessage {
    id = '';
    message = '';
    scheduleDateTime = '';
    relativeInterval = '';
}

export class CrmCustomerTimingChatMessageEditModel {
    id: string | null = null;
    message = '';
    scheduleDate = '';
    scheduleTime = '';
    relativeInterval = '';
}

export class QuickReplyMessageEditModel {
    id: string | null = null;
    message = '';
    leadTypeId: string | null = null;
    leadTypeName = '';
}

export class QuickReplyMessageViewModel {
    id = '';
    message = '';
    leadTypeId: string | null = null;
    leadTypeName = '';
}

export class CrmCustomerMessageAttachment {
    id = '';
    fileName = '';
}
